<template>
  <div class="invite-friends">
    <div class="invite-box">
      <h3 class="secondary">دعوت از دوستان</h3>
      <p class="large">ﻫﺮ ﻓﺮدی ﮐﻪ ﺑﺎ اﺳﺘﻔﺎده از ﮐﺪ دﻋﻮت و ﯾﺎ ﻟﯿﻨﮏ دﻋﻮت ﺷﻤﺎ ﺛﺒﺖ ﻧﺎم ﻧﻤﺎﯾﺪ %20 درﺻﺪ از ﮐﺎرﻣﺰد از ﻫﺮ ﻣﻌﺎﻣﻠﻪ آن ﻓﺮد درﯾﺎﻓﺖ
        می کند ﺑﻪ ﺷﻤﺎ ﺗﻌﻠﻖ می گیرد. همچنین ﻓﺮد دﻋﻮت ﺷﺪه ﻣﯽ ﺗﻮاﻧﺪ دو ﻣﻌﺎﻣﻠﻪ ﺑﺎ ﮐﺎرﻣﺰد ﺻﻔﺮ اﻧﺠﺎم دﻫﺪ و دو ﭘﻠﻪ ﻫﻢ ﮐﺎرﻣﺰد
        ﻣﻌﺎﻣﻠﺎﺗﯽ ﺷﺨﺺ ﮐﺎﻫﺶ پیدامی کند.</p>
      <div class="card-container">
        <div class="card success">
          <div class="content ">
            <span>تعداد دوستان من</span>
            <span>{{invited}}</span>
          </div>
          <img src="@/assets/panel/invited.png" alt="">

        </div>
        <div class="card fail">
          <div class="content">
            <span>دریافتی از دعوت</span>
            <span>{{profit}}</span>
          </div>
          <img src="@/assets/panel/profit.png" alt="">

        </div>


      </div>
      <div class="invite-friends">
<p class="large">دعوت از دوستان از طریق:</p>

      <div class="link-container">
        <span class="alert-link small green" v-if="alertLink">کپی شد</span>
        <span class="gray regular" >لینک</span>
        <div class="input-container">
          <img src="../../../assets/Icons/clipboard.svg" alt="" @click.prevent="copyLink">
          <input type="text" v-model="reffreals" readonly>
        </div>

      </div>
        <div class="link-container">
          <span class="gray regular" >کد</span>
          <span class="alert-code small green" v-if="alertCode" >کپی شد</span>
          <div class="input-container">
            <img src="../../../assets/Icons/clipboard.svg" alt="" @click.prevent="copyCode">
            <input type="text" v-model="refferalsCode" readonly>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InviteFriends',
  data() {
    return {
      alertLink: false,
      alertCode:false,
      invited: 0,
      profit: 0,
      refferalsCode:12334,
      reffreals: "hub.com/register?ref=",
    }
  },
  methods:{
        async getCode() {
      const res = await this.$axios('/users/invites')
      console.table(res);
      this.refferalsCode = res.baseDTO.referralCode
      this.reffreals = this.reffreals + this.refferalsCode
      this.invited = res.baseDTO.countInvitees
      this.profit = res.baseDTO.profit

    },
    copyLink() {
      navigator.clipboard.writeText(this.referrals);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
    copyCode() {
      navigator.clipboard.writeText(this.refferalsCode);
      this.alertCode = true;
      setTimeout(() => {
        this.alertCode = false;
      }, 1500);
    },
  },
    mounted() {
    this.getCode()
  }
}
</script>
<style lang="scss" scoped>
input{
  direction: ltr;
}
.invite-friends {
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 100vw;
  //height: 100vh;
  //padding: 3%;

  .invite-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    width: 100%;
    max-width: 584px;
    //max-height: 582px;
    padding: 24px;
    background: #F8F8F8;
    /* card/def */
    box-shadow: -1px 4px 8px -1px rgba(192, 222, 234, 0.2);
    border-radius: 12px;
    .success{
      background: rgba(0, 103, 143, 0.04) !important;
      color: var(--secondary) !important;
    }
    .fail{
      background: rgba(218, 44, 180, 0.04);
      color: var(--secondary) !important;
    }
  }
}
.card-container{
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: space-between;
  .card{
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    align-items: center;
    padding: 1rem 1.7rem;
    width: 100%;
    max-width: 220px;
    .content{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
.invite-friends{
  display:flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: clamp(12px,2vw,24px);
  .link-container{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 7px;
    flex-wrap: wrap;
    .regular{

      width: 100%;
      max-width: 5%;
      flex: 1 1 auto;
    }
    .input-container{
      flex: 1 1 auto;
      max-width: 491px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 11px;
      border-radius: 8px;
      background: var(--gray-lighter);
    }
  }
.alert-link{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  right: 20%;
}
  .alert-code{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    //top: 40%;
    right: 20%;
      }
}


@media(max-width: 500px){
  .alert-link{
    display: flex;
top: 23% !important;
right: 14% !important;
  }
  .alert-code{


    position: absolute;
bottom:0;
top:42%;
    right: 0;

  }
}
</style>